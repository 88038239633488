
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import Main from './components/main'
//import './css/owl.video.play.png';

import "react-responsive-carousel/lib/styles/carousel.min.css";

//import "react-owl-carousel"


//import "react-owl-carousel2/lib/styles.css"






//import './css/bootstrap.css';


import './css/animate.css';
import './css/bootstrap.min.css';


//import './css/closedhand.html'
import './css/flaticon.css';
import './css/header.css';
import './css/lightbox.css';
//import './css/openhand.html';
import './css/owl.carousel.min.css';
import './css/owl.theme.default.min.css';
//import './css/owl.video.play.html';
import './css/preview.css';
import './css/responsive.css';
import './css/settings.css';
import './css/skin-blue.css';
import './css/skin-green.css';
import './css/skin-red.css';
import './css/style.css';







//import './css/owl.video.play.html';

//require('./css/owl.carousel.min.css')
//require('./css/owl.video.play.html');




//import './css/owl/owl.carousel.min.css';
//import webpack from '../webpack.config.js'
//require("html-loader?interpolate!./file.html");



window.jQuery = require('jquery');
window.$ = require('jquery');

require('materialize-css/js/js/owl.carousel.js');
require('materialize-css/js/js/owl.custom.js');
require('materialize-css/js/js/bootstrap.min.js');
require('materialize-css/js/js/lightbox.js');
require('materialize-css/js/js/jquery.parallax-1.1.3.js');
require('materialize-css/js/js/jquery.localscroll-1.2.7-min.js');
require('materialize-css/js/js/imagesloaded.js');

require('materialize-css/js/js/isotope.min.js');
require('materialize-css/js/js/jquery.counterup.min.js');
require('materialize-css/js/js/jquery.min.js');
//require('materialize-css/js/js/jquery.preloadinator.min.js');
require('materialize-css/js/js/jquery.smartmenus.min.js');


require('materialize-css/js/js/revolution/jquery.themepunch.revolution.min.js');

require('materialize-css/js/js/bootstrap.bundle.js');
require('materialize-css/js/js/bootstrap.bundle.min.js');

require('materialize-css/js/js/revolution/revolution.extension.actions.min.js');
require('materialize-css/js/js/revolution/revolution.extension.layeranimation.min.js');

require('materialize-css/js/js/revolution/revolution.extension.parallax.min.js');
require('materialize-css/js/js/revolution/revolution.extension.slideanims.min.js');
//require('materialize-css/js/js/revolution/jquery.themepunch.tools.min.js');

require('materialize-css/js/js/custom.js');



require('materialize-css/js/js/popper.min.js');







render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'));


//ReactDOM.render(<App />, document.getElementById('root'));

//ReactDOM.render(<Routes history={browserHistory} />, document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
