import React from "react";
import "./t1.css"




class port extends React.Component {
  render() {
    return (
      <div>
      <section id="portfolio" className="index-2">
        <div className="container">
          <div className="section_heading">
            <h2>Portfolio</h2>
            <div className="separator">
              <ul>
                <li />
                <li />
                <li />
              </ul>
            </div>
            <div className="heading_content">
             Wizmen System Complete 500+ Projects And All Client Are Satisfied from Project Performance.
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-xs-12">
          <div  className="button-group filters-button-group portfolioFilter clearfix">
    <button class="button current is-checked b2" data-filter="*">All</button>
    <button class="button b2" data-filter=".beach">Mobile Application</button>
    <button class="button b2" data-filter=".bird">ERP Applications</button>
    <button class="button b2" data-filter=".boat">Web Applications</button>
    <button class="button b2" data-filter=".lighthouse">Cross platform Application</button>
   
</div>
          <section id="" class="transitions-enabled fluid masonry js-masonry grid portfolioContainer" >
    <article class="beach  ">
        
      
    <div className="project portfolio-inner">
                  <img src="img/portfolio/thumbnail/project_1.jpg" alt="project" class="img-responsive"/>
                  <div className="label">
                    <div className="label-text"> <a href="portfolio-detail.html" className="text-title">Order Taking App</a> </div>
                    <div className="label-bg" />
                  </div>
                  <div className="zoom"> <a href="img/portfolio/large/project_1.jpg" className="popup-box" data-lightbox="image" data-title="Project"class="img-responsive"> <i className="flat flaticon-magnifying-glass" /> </a> </div>
                </div>
            
                  
    </article>
    <article class="beach ">
    <div className="project portfolio-inner">
                  <img src="img/portfolio/thumbnail/project_2.jpg" alt="project" class="img-responsive"/>
                  <div className="label">
                    <div className="label-text"> <a href="portfolio-detail.html" className="text-title">Image Uploading App</a> </div>
                    <div className="label-bg" />
                  </div>
                  <div className="zoom"> <a href="img/portfolio/large/project_2.jpg" className="popup-box" data-lightbox="image" data-title="Project"class="img-responsive"> <i className="flat flaticon-magnifying-glass" /> </a> </div>
                </div>
              
    </article>

   
    <article class="beach">
    <div className="project portfolio-inner">
                  <img src="img/portfolio/thumbnail/project_3.jpg" alt="project" class="img-responsive"/>
                  <div className="label">
                    <div className="label-text"> <a href="portfolio-detail.html" className="text-title">Pos</a> </div>
                    <div className="label-bg" />
                  </div>
                  <div className="zoom"> <a href="img/portfolio/large/project_3.jpg" className="popup-box" data-lightbox="image" data-title="Project"class="img-responsive"> <i className="flat flaticon-magnifying-glass" /> </a> </div>
                </div>
    </article>
    
   
    
    
    <article class="beach boat bird">
    <div className="project portfolio-inner">
                  <img src="img/portfolio/thumbnail/project_1.jpg" alt="project" class="img-responsive"/>
                  <div className="label">
                    <div className="label-text"> <a href="portfolio-detail.html" className="text-title">E-commerce</a> </div>
                    <div className="label-bg" />
                  </div>
                  <div className="zoom"> <a href="img/portfolio/large/project_1.jpg" className="popup-box" data-lightbox="image" data-title="Project"class="img-responsive"> <i className="flat flaticon-magnifying-glass" /> </a> </div>
                </div>
    </article>
      



     




    <article class="boat ">
        
      
        <div className="project portfolio-inner">
                      <img src="img/portfolio/thumbnail/project_5.jpg" alt="project" class="img-responsive"/>
                      <div className="label">
                        <div className="label-text"> <a href="portfolio-detail.html" className="text-title">Land Management </a> </div>
                        <div className="label-bg" />
                      </div>
                      <div className="zoom"> <a href="img/portfolio/large/project_5.jpg" className="popup-box" data-lightbox="image" data-title="Project"class="img-responsive"> <i className="flat flaticon-magnifying-glass" /> </a> </div>
                    </div>
                
                      
        </article>
        <article class="bird boat">
        <div className="project portfolio-inner">
                      <img src="img/portfolio/thumbnail/project_6.jpg" alt="project" class="img-responsive"/>
                      <div className="label">
                        <div className="label-text"> <a href="portfolio-detail.html" className="text-title">Payroll</a> </div>
                        <div className="label-bg" />
                      </div>
                      <div className="zoom"> <a href="img/portfolio/large/project_6.jpg" className="popup-box" data-lightbox="image" data-title="Project"class="img-responsive"> <i className="flat flaticon-magnifying-glass" /> </a> </div>
                    </div>
                  
        </article>
    
       
        <article class="beach">
        <div className="project portfolio-inner">
                      <img src="img/portfolio/thumbnail/project_3.jpg" alt="project" class="img-responsive"/>
                      <div className="label">
                        <div className="label-text"> <a href="portfolio-detail.html" className="text-title">Order Uploading App</a> </div>
                        <div className="label-bg" />
                      </div>
                      <div className="zoom"> <a href="img/portfolio/large/project_3.jpg" className="popup-box" data-lightbox="image" data-title="Project"class="img-responsive"> <i className="flat flaticon-magnifying-glass" /> </a> </div>
                    </div>
        </article>
        
       
        
        
        <article class="bird boat">
        <div className="project portfolio-inner">
                      <img src="img/portfolio/thumbnail/project_2.jpg" alt="project" class="img-responsive"/>
                      <div className="label">
                        <div className="label-text"> <a href="portfolio-detail.html" className="text-title">Real Estate</a> </div>
                        <div className="label-bg" />
                      </div>
                      <div className="zoom"> <a href="img/portfolio/large/project_2.jpg" className="popup-box" data-lightbox="image" data-title="Project"class="img-responsive"> <i className="flat flaticon-magnifying-glass" /> </a> </div>
                    </div>
        </article>
          
    
    
    
         
        <article class="boat">
        <div className="project portfolio-inner">
                      <img src="img/portfolio/thumbnail/project_3.jpg" alt="project" class="img-responsive"/>
                      <div className="label">
                        <div className="label-text"> <a href="portfolio-detail.html" className="text-title">Snap To Sell</a> </div>
                        <div className="label-bg" />
                      </div>
                      <div className="zoom"> <a href="img/portfolio/large/project_3.jpg" className="popup-box" data-lightbox="image" data-title="Project"class="img-responsive"> <i className="flat flaticon-magnifying-glass" /> </a> </div>
                    </div>
        </article>
          
    
    




  
        <article class="lighthouse">
        <div className="project portfolio-inner">
                      <img src="img/portfolio/thumbnail/project_1.jpg" alt="project" class="img-responsive"/>
                      <div className="label">
                        <div className="label-text"> <a href="portfolio-detail.html" className="text-title">Flutter</a> </div>
                        <div className="label-bg" />
                      </div>
                      <div className="zoom"> <a href="img/portfolio/large/project_1.jpg" className="popup-box" data-lightbox="image" data-title="Project"class="img-responsive"> <i className="flat flaticon-magnifying-glass" /> </a> </div>
                    </div>
        </article>
            
        <article class="lighthouse">
        <div className="project portfolio-inner">
                      <img src="img/portfolio/thumbnail/project_3.jpg" alt="project" class="img-responsive"/>
                      <div className="label">
                        <div className="label-text"> <a href="portfolio-detail.html" className="text-title">React Native</a> </div>
                        <div className="label-bg" />
                      </div>
                      <div className="zoom"> <a href="img/portfolio/large/project_3.jpg" className="popup-box" data-lightbox="image" data-title="Project"class="img-responsive"> <i className="flat flaticon-magnifying-glass" /> </a> </div>
                    </div>
        </article>
            
        <article class="bird">
        <div className="project portfolio-inner">
                      <img src="img/portfolio/thumbnail/project_5.jpg" alt="project" class="img-responsive"/>
                      <div className="label">
                        <div className="label-text"> <a href="portfolio-detail.html" className="text-title">Capital Management</a> </div>
                        <div className="label-bg" />
                      </div>
                      <div className="zoom"> <a href="img/portfolio/large/project_5.jpg" className="popup-box" data-lightbox="image" data-title="Project"class="img-responsive"> <i className="flat flaticon-magnifying-glass" /> </a> </div>
                    </div>
        </article>
          



        <article class="bird">
        <div className="project portfolio-inner">
                      <img src="img/portfolio/thumbnail/project_4.jpg" alt="project" class="img-responsive"/>
                      <div className="label">
                        <div className="label-text"> <a href="portfolio-detail.html" className="text-title">Financial System</a> </div>
                        <div className="label-bg" />
                      </div>
                      <div className="zoom"> <a href="img/portfolio/large/project_4.jpg" className="popup-box" data-lightbox="image" data-title="Project"class="img-responsive"> <i className="flat flaticon-magnifying-glass" /> </a> </div>
                    </div>
        </article>


        <article class="bird">
        <div className="project portfolio-inner">
                      <img src="img/portfolio/thumbnail/project_1.jpg" alt="project" class="img-responsive"/>
                      <div className="label">
                        <div className="label-text"> <a href="portfolio-detail.html" className="text-title">Retail Management</a> </div>
                        <div className="label-bg" />
                      </div>
                      <div className="zoom"> <a href="img/portfolio/large/project_1.jpg" className="popup-box" data-lightbox="image" data-title="Project"class="img-responsive"> <i className="flat flaticon-magnifying-glass" /> </a> </div>
                    </div>
        </article>


   
</section></div>

        </div>
        <div className="overlay" />
      </section>
      <br/> <br/> <br/>
      </div>
    );
  }
}

export default port;
