import React, { Component } from 'react';


class Portfolio_Banner extends Component {
   
    render() { 
        return (
          <section id="inner-banner">
          <div className="container">
            <div className="row">
              <div className="inner-heading">
                <h2>Portfolio</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Portfolio</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

          );
    }
}
 
export default Portfolio_Banner;