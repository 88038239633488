import React, { Component } from 'react';
import { Switch, Route ,Redirect} from 'react-router-dom'

import Header from '../components/head'
import Footer from '../components/footer';
import Home from './Home'
import About from './about'


import Services from './services'
import Portfolio from './portfolio'
import Blogs from './blogs'
import Contact from './contactus'
import Career from './career_page'
import { Link } from 'react-router-dom'






class Main extends Component {
  
    render() { 
        return ( <div>
           
            <Switch>
            <Route exact path='/' component={Home}/>
            <Route path='/About' component={About}/>
            <Route path='/Services' component={Services}/>
            <Route path='/Portfolio' component={Portfolio}/>
            <Route path='/Blogs' component={Blogs}/>
            <Route path='/Contact' component={Contact}/>
            <Route path='/Career' component={Career}/>
           
          </Switch>
         
          </div>
         );
    }
}
 
export default Main ;


