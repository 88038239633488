import React, { Component } from 'react';


class About_Qualities extends Component {
   
    render() { 
        return (
            <section id="qualities" className="index-2">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="content">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 column">
                  <div className="icon"><i className="flat flaticon-reward" /></div>
                  <div className="heading">
                    <h5>We are Professional</h5>
                  </div>
                  <div className="description">
                    <p>Our Team is a very balance and blend of Business and IT Professional Including Business Analyst Functional Consultants,Developer,Implementer,<br/>QA Personnel,Configration Mangers and Support person. </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 column">
                  <div className="icon"><i className="flat flaticon-certificate" /></div>
                  <div className="heading">
                    <h5>Licensed and Certified</h5>
                  </div>
                  <div className="description">
                    <p>Choosing the right software brand for your organization could be difficult.But selecting an authentic brand helps you out in a better respect.<br/>You not only have got beautiful viable Quality Solution to benefit for many years,You have also saved yourself a good amount of money and time by shopping the Wizmen way.</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 column">
                  <div className="icon"><i className="flat flaticon-enterprise" /></div>
                  <div className="heading">
                    <h5>Number 1 Company</h5>
                  </div>
                  <div className="description">
                    <p>Top Rated Software Company since 2000-19.ERP Solution Providing Company Wide-ranging e-Commerce, Mobile Application Solutions. </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 column">
                  <div className="icon"><i className="flat flaticon-working-team" /></div>
                  <div className="heading">
                    <h5>Leading Provider</h5>
                  </div>
                  <div className="description">
                    <p>Wizmen System Provide Smart solutions to Grow your Business And Complete your Dream In Industry. </p>
                  </div>
                </div>
              </div></div>
          </div>
        </div>
      </section>

          );
    }
}
 
export default About_Qualities;