import React, { Component } from 'react';


class About_Counter extends Component {
   
    render() { 
        return (
         
            <section id="counter" className="counter-inner">
            <div className="container">
              <div className="col-lg-12">
                <div className="row">
                  <div className="counter-column col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="count_icon"> <i className="flat flaticon-happiness" /> </div>
                    <div className="counter_content">
                      <h2><span className="counter">200</span>+</h2>
                      <div className="title">Happy Client</div>
                    </div>
                  </div>
                  <div className="counter-column col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="count_icon"> <i className="flat flaticon-coffee-cup" /> </div>
                    <div className="counter_content">
                      <h2><span className="counter">750</span>+</h2>
                      <div className="title">Cups of Coffee</div>
                    </div>
                  </div>
                  <div className="counter-column col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="count_icon"> <i className="flat flaticon-like" /> </div>
                    <div className="counter_content">
                      <h2><span className="counter">500</span>+</h2>
                      <div className="title">Projects Done</div>
                    </div>
                  </div>
                  <div className="counter-column col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="count_icon"> <i className="flat flaticon-building" /> </div>
                    <div className="counter_content">
                      <h2><span className="counter">2</span></h2>
                      <div className="title">Office Locations</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overlay" />
          </section>
          );
    }
}
 
export default About_Counter;