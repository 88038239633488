import React from "react";

class service extends React.Component {
  render() {
    return (
      <section id="services" className="gray-bg index-2">
        <div className="container">
          <div className="section_heading">
            <h2>Services</h2>
            <div className="separator">
              <ul>
                <li />
                <li />
                <li />
              </ul>
            </div>
            
          </div>
          <div className="col-lg-12 col-md-12 col-xs-12 content_sec">
            <div className="service-slider owl-one owl-carousel owl-theme">
              <div className="item">
                <div className="service_icon">
                  <i className="flat flaticon-web-design" />
                </div>
                <div className="connector">
                  {" "}
                  <span />{" "}
                </div>
                <div className="service-card">
                  <div className="service_detail">
                  <h5><a href="service-detail.html" title="Web Development">ERP Applications</a></h5>
                <p>WIZMEN Financials System<br/>WIZMEN Supply Chain Management System<br/>WIZMEN Human Capital Management<br/>WIZMEN Retail Management System<br/>WIZMEN Textiles<br/>WIZMEN Construction System<br/>WIZMEN Real Estate System<br/>WIZMEN Restaurant Management System<br/>WIZMEN Manufacturing System</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="service_icon">
                  <i className="flat flaticon-computer-graphic" />
                </div>
                <div className="connector">
                  {" "}
                  <span />{" "}
                </div>
                <div className="service-card">
                  <div className="service_detail">
                  <h5><a href="service-detail.html" title="Web Design">Mobile Applications</a></h5>
                <p>Order booking<br/>
                 Payment Collection<br/>
                 Point of Sales<br/>
                 Order Pulling from Warehouse<br/>
                 Business Pictures Shooting <br/>
                 Self Service Payroll<br/>
                 ERP Document Authorization<br/>
                 Business Notifications<br/>
</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="service_icon">
                  <i className="flat flaticon-servers" />
                </div>
                <div className="connector">
                  {" "}
                  <span />{" "}
                </div>
                <div className="service-card">
                  <div className="service_detail">
                  <h5><a href="service-detail.html" title="Web Hosting">Web Applications</a></h5>
                <p>eBay Merchant Solution <br/>Sell-A-Gadget Online<br/> Land mangment system <br/>POS<br/> Customer Portals<br/>Complain mangment system<br/> API services</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="service_icon">
                  <i className="flat flaticon-digital-marketing" />
                </div>
                <div className="connector">
                  {" "}
                  <span />{" "}
                </div>
                <div className="service-card">
                  <div className="service_detail">
                  <h5><a href="service-detail.html" title="Digital Marketing">IOS Application </a></h5>
                <p>All Mobile Application are Develop in Flutter And React Native.</p>
                  </div>
                </div>
              </div>
             
             
             
            
            
              
            </div>
            <div className="h-border">
              <span />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default service;
