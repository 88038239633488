import React, { Component } from 'react';


class Blogs extends Component {
   
    render() { 
        return (
           
          <section id="blog_post" className="index-2">
          <div className="container">
            <div className="col-lg-12 col-md-12 col-xs-12">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div className="item blog_card">
                    <div className="post-img"> <img src="img/blog-post-1.jpg" alt="Post" />
                      <div className="posted_on"> <span className="date">04</span> <span className="month">Sept.</span> </div>
                    </div>
                    <div className="post-detail">
                      <h5><a href="blog-detail.html">Standard is simply dummy</a></h5>
                      <div className="post-status">
                        <ul>
                          <li><i className="flat flaticon-folder" /> <span><a href="javascript:void(0)">UI Developer</a></span></li>
                          <li><i className="flat flaticon-chat" /> <span>20</span></li>
                        </ul>
                      </div>
                      <div className="description">
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <a href="blog-detail.html" className="view_more" title="View More">View More</a> </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div className="item blog_card">
                    <div className="post-img"> <img src="img/blog-post-2.jpg" alt="Post" />
                      <div className="posted_on"> <span className="date">05</span> <span className="month">Sept.</span> </div>
                    </div>
                    <div className="post-detail">
                      <h5><a href="blog-detail.html">If you are going to use a passage</a></h5>
                      <div className="post-status">
                        <ul>
                          <li><i className="flat flaticon-folder" /> <span><a href="javascript:void(0)">UI Developer</a></span></li>
                          <li><i className="flat flaticon-chat" /> <span>20</span></li>
                        </ul>
                      </div>
                      <div className="description">
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <a href="blog-detail.html" className="view_more" title="View More">View More</a> </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div className="item blog_card">
                    <div className="post-img"> <img src="img/blog-post-3.jpg" alt="Post" />
                      <div className="posted_on"> <span className="date">06</span> <span className="month">Sept.</span> </div>
                    </div>
                    <div className="post-detail">
                      <h5><a href="blog-detail.html">There are many variations</a></h5>
                      <div className="post-status">
                        <ul>
                          <li><i className="flat flaticon-folder" /> <span><a href="javascript:void(0)">UI Developer</a></span></li>
                          <li><i className="flat flaticon-chat" /> <span>20</span></li>
                        </ul>
                      </div>
                      <div className="description">
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <a href="blog-detail.html" className="view_more" title="View More">View More</a> </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div className="item blog_card">
                    <div className="post-img"> <img src="img/blog-post-4.jpg" alt="Post" />
                      <div className="posted_on"> <span className="date">07</span> <span className="month">Sept.</span> </div>
                    </div>
                    <div className="post-detail">
                      <h5><a href="blog-detail.html">Standard is simply dummy</a></h5>
                      <div className="post-status">
                        <ul>
                          <li><i className="flat flaticon-folder" /> <span><a href="javascript:void(0)">UI Developer</a></span></li>
                          <li><i className="flat flaticon-chat" /> <span>20</span></li>
                        </ul>
                      </div>
                      <div className="description">
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <a href="blog-detail.html" className="view_more" title="View More">View More</a> </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div className="item blog_card">
                    <div className="post-img"> <img src="img/blog-post-5.jpg" alt="Post" />
                      <div className="posted_on"> <span className="date">08</span> <span className="month">Sept.</span> </div>
                    </div>
                    <div className="post-detail">
                      <h5><a href="blog-detail.html">If you are going to use a passage</a></h5>
                      <div className="post-status">
                        <ul>
                          <li><i className="flat flaticon-folder" /> <span><a href="javascript:void(0)">UI Developer</a></span></li>
                          <li><i className="flat flaticon-chat" /> <span>20</span></li>
                        </ul>
                      </div>
                      <div className="description">
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <a href="blog-detail.html" className="view_more" title="View More">View More</a> </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div className="item blog_card">
                    <div className="post-img"> <img src="img/blog-post-6.jpg" alt="Post" />
                      <div className="posted_on"> <span className="date">09</span> <span className="month">Sept.</span> </div>
                    </div>
                    <div className="post-detail">
                      <h5><a href="blog-detail.html">There are many variations</a></h5>
                      <div className="post-status">
                        <ul>
                          <li><i className="flat flaticon-folder" /> <span><a href="javascript:void(0)">UI Developer</a></span></li>
                          <li><i className="flat flaticon-chat" /> <span>20</span></li>
                        </ul>
                      </div>
                      <div className="description">
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <a href="blog-detail.html" className="view_more" title="View More">View More</a> </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
            </div>
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                <li className="page-item disabled"> <a className="page-link" href="#" tabIndex={-1}><i className="flat flaticon-back" /></a> </li>
                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item"> <a className="page-link" href="#"><i className="flat flaticon-arrow" /></a> </li>
              </ul>
            </nav>
          </div>
        </section>
          );
    }
}
 
export default Blogs;



