import React, { Component } from 'react';
import { Carousel } from "react-responsive-carousel";
import { Link } from 'react-router-dom'
import slide_1 from '../img/slide-1.jpg';
import slide_2 from '../img/slide-2.jpg';
import slide_3 from '../img/slide-3.jpg';
import slide_4 from '../img/slide-4.jpg';

function refreshPage(){ 
  window.location.reload(); 
}


class Slider_3 extends Component {
   
    render() { 
        return ( 
        <div className="slider index-2">
        <Carousel    infiniteLoop autoPlay  showStatus={false} showThumbs={false} interval={2000} selectedItem={3} className="owl-main ">
        
            <div className="item " >
              <img className="d-block w-100" src={slide_4} alt="First slide"/>
             
              <div className="caption d-md-block ">
              
            <div className="hello">Highly Flexible WIZMEN ERP (20+ Modules) capable of accommodating Various Business Processes in Diversified Business Sectors</div>
           
           
            {/* <a href="about-us-2.html" className="view_more" title="View More">View More</a>  */}
            <ul><li onClick={refreshPage}>
           <Link to='/about' className="view_more" title="View More" >View More</Link> 
           <Link to='/Contact' className="view_more contact_us" title="Contact Us" >Contact Us</Link> </li>
           </ul>
          </div>  
     
  
    
            </div>
            <div className="item">
              <img className="d-block w-100" src={slide_2} alt="Second slide"/>
              <div className="caption d-md-block">
              <div className="hello">Modern & State of the Art e-Commerce, Android Apps and Web Application</div>
          
            
            {/* <a href="about-us-2.html" className="view_more" title="View More">View More</a>  */}
            <ul><li onClick={refreshPage}>
           <Link to='/about' className="view_more" title="View More" >View More</Link> 
           <Link to='/Contact' className="view_more contact_us" title="Contact Us" >Contact Us</Link> </li>
           </ul>
          </div>  
     
  
    
            </div>
            <div className="item">
              <img className="d-block w-100" src={slide_3} alt="Third slide"/>
              <div className="caption d-md-block">
              <div className="hello">500+ Clients (Textile, Construction, Real Estate, Retail, NGO, Distributors, Other) in Pakistan, Middle East & USA</div>
            
            {/* <a href="about-us-2.html" className="view_more" title="View More">View More</a>  */}
            <ul><li onClick={refreshPage}>
           <Link to='/about' className="view_more" title="View More" >View More</Link> 
           <Link to='/Contact' className="view_more contact_us" title="Contact Us" >Contact Us</Link> </li>
           </ul>
          </div>  
     
  
    
            </div>

            <div className="item">
              <img className="d-block w-100" src={slide_2} />
              <div className="caption d-md-block">
              <div className="hello"> Multi-Dimensional Business Solution Providing I.T. Company</div>
            
            {/* <a href="about-us-2.html" className="view_more" title="View More">View More</a>  */}
            <ul><li onClick={refreshPage}>
           <Link to='/about' className="view_more" title="View More" >View More</Link> 
           <Link to='/Contact' className="view_more contact_us" title="Contact Us" >Contact Us</Link> </li>
           </ul>
          </div>  
     
  
    
            </div>




            <div className="item">
              <img className="d-block w-100" src={slide_1} alt="Fourth slide" />
              <div className="caption d-md-block">
              <div className="hello">20+ Years of Vast Experienced Software House serving Small to Large Organization</div>
             
            {/* <a href="about-us-2.html" className="view_more" title="View More">View More</a>  */}
            <ul><li onClick={refreshPage}>
           <Link to='/about' className="view_more" title="View More" >View More</Link> 
           <Link to='/Contact' className="view_more contact_us" title="Contact Us" >Contact Us</Link> </li>
           </ul>
          </div>  
     
  
    
            </div>
           
          </Carousel>
          
          
          </div>
         
          
          
          
          );
    }
}
 
export default Slider_3;