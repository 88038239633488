import React, { Component } from 'react';
//import Slider from './components/slide';
import "../index.js";

import App from '../App' 
//import Header from '../components/head';
import Slider from '../components/slide';

import Slider_6 from '../components/slider6';
import About from '../components/abouts';
import Services from '../components/service';
import Work from '../components/work';
import Team from '../components/team';
import Portfolio from '../components/port';
import Pricing from '../components/price';
import Counter from '../components/count';
import Blog from '../components/blog';
import Clients from '../components/clients';
import Testimonials from '../components/testimonials';
//import Footer from '../components/footer';

import ContactForm from '../components/contactform';


import Banner from './career_banner'
import Career from './career'

//import Test from './test' 
const Careerpage = () => (
  <div >
  

     
       <div><Banner /></div>
      
       <div><Career/></div>
       {/* <div><Test/></div> */}
  
</div>
)

export default Careerpage
