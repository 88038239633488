import React, { Component } from 'react';


class Contact_Banner extends Component {
   
    render() { 
        return (
            <section id="inner-banner">
            <div className="container">
              <div className="row">
                <div className="inner-heading">
                  <h2>Contact Us</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                      <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </section>
          );
    }
}
 
export default Contact_Banner;