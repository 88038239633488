import React from "react";

class work extends React.Component {
  render() {
    return (
      <section id="work-status" className="index-2">
        <div className="container">
          <div className="section_heading ">
            <h2>Heading</h2>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="counter-column col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="counter_content">
                  <div className="display">
                    <span className="counter">95</span>%
                  </div>
                  <div className="title">ERP Application</div>
                </div>
              </div>
              <div className="counter-column col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="counter_content">
                  <div className="display">
                    <span className="counter">80</span>%
                  </div>
                  <div className="title">Web Development</div>
                </div>
              </div>
              <div className="counter-column col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="counter_content">
                  <div className="display">
                    <span className="counter">70</span>%
                  </div>
                  <div className="title">Mobile Application</div>
                </div>
              </div>
              <div className="counter-column col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="counter_content">
                  <div className="display">
                    <span className="counter">75</span>%
                  </div>
                  <div className="title">Cross platform</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay" />
      </section>
      
    );
  }
}

export default work;
