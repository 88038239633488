import React, { Component } from 'react';


class Service extends Component {
   
    render() { 
        return (
<section id="services" className="index-2 pdb-70">
<div className="container">
  <div className="col-lg-12 col-md-12 col-xs-12">
    <div className="row blog-isotope">
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 blog-iso-item">
        <div className="item card_mr_top">
          <div className="service-card">
            <div className="service_icon"><i className="flat flaticon-web-design" /></div>
            <div className="service_detail">
              <h5> <a href="service-detail.html" title="Web Development">ERP Applications </a></h5>
              <p>WIZMEN Financials System<br/>WIZMEN Supply Chain Management System<br/>WIZMEN Human Capital Management<br/>WIZMEN Retail Management System<br/>WIZMEN Textiles<br/>WIZMEN Construction System<br/>WIZMEN Real Estate System<br/>WIZMEN Restaurant Management System<br/>WIZMEN Manufacturing System</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 blog-iso-item">
        <div className="item card_mr_top">
          <div className="service-card">
            <div className="service_icon"><i className="flat flaticon-computer-graphic" /></div>
            <div className="service_detail">
              <h5> <a href="service-detail.html" title="Web Design">Mobile Applications</a></h5>
              <p>Order booking<br/>
                 Payment Collection<br/>
                 Point of Sales<br/>
                 Order Pulling from Warehouse<br/>
                 Business Pictures Shooting <br/>
                 Self Service Payroll<br/>
                 ERP Document Authorization<br/>
                 Business Notifications<br/>
</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 blog-iso-item">
        <div className="item card_mr_top">
          <div className="service-card">
            <div className="service_icon"><i className="flat flaticon-servers" /></div>
            <div className="service_detail">
              <h5> <a href="service-detail.html" title="Web Hosting">Web Applications</a></h5>
              <p>eBay Merchant Solution <br/>Sell-A-Gadget Online<br/> Land mangment system <br/>POS<br/> Customer Portals<br/>Complain mangment system<br/> API services</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 blog-iso-item">
        <div className="item card_mr_top">
          <div className="service-card">
            <div className="service_icon"><i className="flat flaticon-digital-marketing" /></div>
            <div className="service_detail">
              <h5> <a href="service-detail.html" title="Digital Marketing">IOS Application</a></h5>
              <p>All Mobile Application are Develop in Flutter And React Native.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 blog-iso-item">
        <div className="item card_mr_top">
          <div className="service-card">
            <div className="service_icon"><i className="flat flaticon-digital-marketing" /></div>
            <div className="service_detail">
              <h5> <a href="service-detail.html" title="Digital Marketing">Business Intelligence</a></h5>
              <p>Tableau<br/>Oracle BigData </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 blog-iso-item">
        <div className="item card_mr_top">
          <div className="service-card">
            <div className="service_icon"><i className="flat flaticon-servers" /></div>
            <div className="service_detail">
              <h5> <a href="service-detail.html" title="Web Hosting">Cloud Solution</a></h5>
              <p>WIZ-ERP on the Cloud(Write Note) </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 blog-iso-item">
        <div className="item card_mr_top">
          <div className="service-card">
            <div className="service_icon"><i className="flat flaticon-computer-graphic" /></div>
            <div className="service_detail">
              <h5> <a href="service-detail.html" title="Web Design">Hardware Integrations</a></h5>
              <p>Weighing Scales <br/>Biometric Attendence Devices<br/>Biometric Recognition Device<br/>Barcode Readers<br/>RFID Readers & Scanners<br/>Point Of Sales Drawers<br/> Digital Cameras<br/>Digital Meter Reading<br/>Document Scanners </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 blog-iso-item">
        <div className="item card_mr_top">
          <div className="service-card">
            <div className="service_icon"><i className="flat flaticon-web-design" /></div>
            <div className="service_detail">
              <h5> <a href="service-detail.html" title="Web Development">Web Development</a></h5>
              <p>Php CodeIgniter<br/>React<br/>Oracle Firebase<br/>Javascript,HTML,CSS</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>
          );
        }
    }
     
    export default Service;