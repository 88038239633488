import React, { Component } from 'react';


class Blog_Banner extends Component {
   
    render() { 
        return (
          <section id="inner-banner" className="index-2">
          <div className="container">
            <div className="col-lg-12 col-md-12 col-xs-12">
              <div className="inner-heading">
                <h2>Blog 3 Column</h2>
                <div className="separator left-align">
                  <ul>
                    <li />
                    <li />
                    <li />
                  </ul>
                </div>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="index-2.html" title="Home">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Blog</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
          );
    }
}
 
export default Blog_Banner;