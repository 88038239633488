import React from "react";

class count extends React.Component {
  render() {
    return (
      <section id="counter" className="gray-bg index-2">
        <div className="container">
          <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
            <div className="row row-safari">
              <div className="counter-column col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="count_icon">
                  {" "}
                  <i className="flat flaticon-happiness" />{" "}
                </div>
                <div className="separator left-align">
                  <ul>
                    <li />
                    <li />
                    <li />
                  </ul>
                </div>
                <div className="counter_content">
                  <h2>
                    <span className="counter">200</span>+
                  </h2>
                  <div className="title">Happy Client</div>
                </div>
              </div>
              <div className="counter-column col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="count_icon">
                  {" "}
                  <i className="flat flaticon-coffee-cup" />{" "}
                </div>
                <div className="separator left-align">
                  <ul>
                    <li />
                    <li />
                    <li />
                  </ul>
                </div>
                <div className="counter_content">
                  <h2>
                    <span className="counter">750</span>+
                  </h2>
                  <div className="title">Cups of Coffee</div>
                </div>
              </div>
              <div className="counter-column col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="count_icon">
                  {" "}
                  <i className="flat flaticon-like" />{" "}
                </div>
                <div className="separator left-align">
                  <ul>
                    <li />
                    <li />
                    <li />
                  </ul>
                </div>
                <div className="counter_content">
                  <h2>
                    <span className="counter">500</span>+
                  </h2>
                  <div className="title">Projects Done</div>
                </div>
              </div>
              <div className="counter-column col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="count_icon">
                  {" "}
                  <i className="flat flaticon-building" />{" "}
                </div>
                <div className="separator left-align">
                  <ul>
                    <li />
                    <li />
                    <li />
                  </ul>
                </div>
                <div className="counter_content">
                  <h2>
                    <span className="counter">2</span>
                  </h2>
                  <div className="title">Office Locations</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default count;
