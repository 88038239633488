import React, { Component } from 'react';
import axios from "axios";
import './form.css' 
function myFunction() {
  document.getElementById("myForm").reset();
}


function  validateForm1(){
  var a = document.forms["myForm1"]["company"].value;
  var b = document.forms["myForm1"]["fname"].value;
  var c = document.forms["myForm1"]["title"].value;
  var d = document.forms["myForm1"]["phone"].value;
  var e = document.forms["myForm1"]["email"].value;

  var f = document.forms["myForm1"]["website"].value;
  var g = document.forms["myForm1"]["interest"].value;
  var h = document.forms["myForm1"]["country"].value;
  var i = document.forms["myForm1"]["specialize"].value;
  var j = document.forms["myForm1"]["employees"].value;
  var k = document.forms["myForm1"]["turnover"].value;



  if (a == "") {
    alert("Company Name must be filled out");
    return false;
  }
  
   if (b == "") {
    alert("Full Name must be filled out");
    return false;
  }
  if (c == "") {
    alert("Title must be filled out");
    return false;
  }
  if (d == "") {
    alert("Phone must be filled out");
    return false;
  }
  if (e == "") {
    alert("Email must be filled out");
    return false;
  }
  if (f == "") {
    alert("Website must be filled out");
    return false;
  }
  if (g == "") {
    alert("Interest must be filled out");
    return false;
  }
  if (h == "") {
    alert("Country must be filled out");
    return false;
  }
  if (i == "") {
    alert("Specialize must be filled out");
    return false;
  }
  if (j == "") {
    alert("Number of Employees must be filled out");
    return false;
  }
  if (k == "") {
    alert("Turnover must be filled out");
    return false;
  }else{
    return true;
  }

}

class Inquiry_form extends Component {
   
    constructor() {
        super();
        this.state = {
         site_url:'http://localhost/wiztest/index.php',
         error: {},
         btnloading: false,
         company:'',
         fname: '',
         title: '',
         phone:'',
         email:'',
         website: '',
         interest:'',
         interest_description:'',
         country:'',
         specialize: '',
         employees:'',
         turnover: ''
        }
        this.onChange = this.onChange.bind(this);
      }
      componentWillMount(){
          this.setState({
              //site_url: window['site_url']
          });
      }
      componentDidMount(){
    
      }
    
    
    onChange(e){
    this.setState({[e.target.name]: e.target.value});
    }
    
    
    
    
    submitBiodata(e)
    {
      if(validateForm1()){
    e.preventDefault();
    this.setState({btnloading:true});
    const formData = new FormData();
    formData.append('company',this.state.company);
    formData.append('fname',this.state.fname);
    formData.append('title',this.state.title);
    formData.append('phone',this.state.phone);
    formData.append('email',this.state.email);
    formData.append('website',this.state.website);
    formData.append('interest',this.state.interest);
    formData.append('interest_description',this.state.interest_description);
    formData.append('country',this.state.country);
    formData.append('specialize',this.state.specialize);
    formData.append('employees',this.state.employees);
    formData.append('turnover',this.state.turnover);
    
    
    axios.post('http://localhost/wiztest/composer/contactus_inquiry.php',formData).then(res => {});  
  
    axios.post(this.state.site_url+ '/welcome/inquiry',formData).then(res => {
        const response =res.data;
    
        if(response.status){
            this.setState({error:{}});
            alert(response.message);
    
            this.setState({
                company:'',
                fname: '',
                title: '',
                phone:'',
                email:'',
                website: '',
                interest:'',
                interest_description:'',
                country:'',
                specialize: '',
                employees:'',
                turnover: ''
    
            });
        }else{
           
            this.setState({error:response.error});
            alert(response.message);
        }
    
    }).then(() =>{
        this.setState({btnloading:false});
    }).catch(error =>{
        alert(error.response.status +':'+ error.response.statusText);
      
    });


    myFunction();
 
}
    
  
 }
    render() { 
        return (
            <section id="contact-us">
<div className="container">

                    
                    <div className="form-header text-center">
                      <h4>Inquiry Form</h4>
                      <div className="separator">
                        <ul>
                          <li />
                          <li />
                          <li />
                        </ul> 
                      </div>
                      <p>Type: ERP, e-Commerce, Mobile Application, Website</p>
                    </div>
<div className="contact-form ">

<form  name="myForm" action="" method="POST" id="myForm1" onsubmit="return validateForm1()">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <div className="form-group">
                              
                                <input defaultValue type="text" placeholder="Company" name="company" value={this.setState.company} onChange={this.onChange}  defaultValue={""} required/>
                              </div>

                           
                              <div className="form-group">
                                <input defaultValue type="text" value={this.setState.fname} onChange={this.onChange} name="fname"  placeholder="Name" defaultValue={""} required/>
                              </div>



                              <div className="form-group">
                                <input defaultValue type="text" value={this.setState.title} onChange={this.onChange} name="title"  placeholder="Title" defaultValue={""} required/>
                              </div>

                              

                              <div className="form-group">
                                <input defaultValue type="text" value={this.setState.phone} onChange={this.onChange} placeholder="Phone No." name="phone" defaultValue={""}/>
                              </div>
                              <div className="form-group">
   <input defaultValue type="text" value={this.setState.email} onChange={this.onChange} placeholder="Email" name="email" defaultValue={""}/>
             </div>


             <div className="form-group">
   <input defaultValue type="text" value={this.setState.website} onChange={this.onChange} placeholder="Website" name="website" defaultValue={""}/>
             </div>

             <div class="form-group">
                              <h6>Interest*</h6>     
  <select class="form-control" id="sel1" name="interest" type="text" value={this.setState.interest} onChange={this.onChange} required>
  
  <option value="" selected disabled>Please Select</option>
  <option>ERP</option>
  <option>E-Commerce</option>
  <option>Mobile App</option>
  <option>Website</option>
  <option>other</option>
  </select>
</div>
             <div className="form-group">
   <input defaultValue type="text" value={this.setState.interest_description} onChange={this.onChange} placeholder="Interest description" name="interest_description" defaultValue={""}/>
             </div>


                              <div class="form-group">
                              <h6>Country*</h6>     
  <select class="form-control" id="sel1" name="country" type="text" value={this.setState.country} onChange={this.onChange} required>
  
  <option value="" selected disabled>Please Select</option>
  <option>Pakistan</option>
  <option>other</option>
  </select>
</div>

                              <div className="form-group">
                              <h6>What verticals do you currently Specialize In?*</h6>
                              <select class="form-control" id="sel1" name="specialize" type="text" value={this.setState.specialize} onChange={this.onChange} required>
                              <option value="" selected disabled>Please Select</option>
 
    <option>Textile</option>
    <option>RealEstate</option>
    <option>Transportation</option>
    <option>Government</option>
    <option>Telco</option>
    <option>IT & Technology</option>
    <option>HealthCare</option>
    <option>Oil & gas</option>
    <option>Banking & Finance</option>
    <option>Education</option>
    <option>Retail</option>
    <option>Other</option>
  </select>
                              </div>
                              
         


                             
                            
   
                              
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            
                            
                            <div className="form-group">
                              <h6>What is your company size number of employees?*</h6>
                              <select class="form-control" id="sel1" name="employees" type="text" value={this.setState.employees} onChange={this.onChange} required>
                              <option value="" selected disabled>Please Select</option>
 
    <option>50</option>
    <option>50-100</option>
    <option>100-200</option>
    <option>200-500</option>
    <option>500-1000</option>
    <option>Above 1000</option>
  </select>
                              </div>
                            
                            <div className="form-group">
                              <h6>What is your Annual Turnover?*</h6>
                              <select class="form-control" id="sel1" name="turnover" type="text" value={this.setState.turnover} onChange={this.onChange} required>
                              <option value="" selected disabled>Please Select</option>
 
    <option>Less than Rs 10M</option>
    <option>Rs 10M-100M</option>
    <option>Rs 100M-500M</option>
    <option>Above Rs 500M</option>
   
  </select>
                              </div>
                             
                            </div>
                            
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group text-center mar-none">
                            <button disabled={this.state.btnloading}
                onClick={this.submitBiodata.bind(this)}
                type="submit">{this.state.btnloading ? 'loading...':'Submit'}<i className="flat flaticon-plane" /></button>
                            </div>
                          </div>
                        </form>




</div></div></section>
          );
         
    }
}
 
export default Inquiry_form;