import React from "react";
//import slide_1 from '../img/slide-1.jpg';
//import slide_2 from '../img/slide-2.jpg';
//import slide_3 from '../img/slide-3.jpg';
//import slide_4 from '../img/slide-4.jpg';
class slide extends React.Component {
  render() {
    return (
      <div className="slider index-2">
        <div className="owl-main owl-four  owl-carousel owl-theme">
          <div className="item">
            <img
              className="d-block w-100"
              src="img/slide-1.jpg"
              alt="First slide"
            />
            <div className="caption d-md-block">
              <div className="hello">Hello</div>
              <div className="display">
                Think Big <br /> Do Creative <br /> Grow Business{" "}
              </div>
              <div className="content">
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </div>
              <a href="about-us-2.html" className="view_more" title="View More">
                View More
              </a>
              <a
                href="contact-us-1.html"
                className="view_more contact_us"
                title="Contact Us"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div className="item">
            <img
              className="d-block w-100"
              src="img/slide-2.jpg"
              alt="Second slide"
            />
            <div className="caption d-md-block">
              <div className="hello">Hello</div>
              <div className="display">
                Give Wings <br /> To Your <br /> Business{" "}
              </div>
              <div className="content">
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </div>
              <a href="about-us-2.html" className="view_more" title="View More">
                View More
              </a>
              <a
                href="contact-us-1.html"
                className="view_more contact_us"
                title="Contact Us"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div className="item">
            <img
              className="d-block w-100"
              src="img/slide-3.jpg"
              alt="Third slide"
            />
            <div className="caption d-md-block">
              <div className="hello">Hello</div>
              <div className="display">
                We Are
                <br />
                Your Digital <br /> Partners{" "}
              </div>
              <div className="content">
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </div>
              <a href="about-us-2.html" className="view_more" title="View More">
                View More
              </a>
              <a
                href="contact-us-1.html"
                className="view_more contact_us"
                title="Contact Us"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div className="item">
            <img
              className="d-block w-100"
              src="img/slide-4.jpg"
              alt="Fourth slide"
            />
            <div className="caption d-md-block">
              <div className="hello">Hello</div>
              <div className="display">
                We Are Bizler
                <br />
                We Make
                <br />
                Brands Shine{" "}
              </div>
              <div className="content">
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </div>
              <a href="about-us-2.html" className="view_more" title="View More">
                View More
              </a>
              <a
                href="contact-us-1.html"
                className="view_more contact_us"
                title="Contact Us"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default slide;
