import React from "react";

class blog extends React.Component {
  render() {
    return (
      <section id="blog_post" className="index-2">
        <div className="container">
          <div className="section_heading">
            <h2>
              Blog <span>Posts</span>
            </h2>
            <div className="separator">
              <ul>
                <li />
                <li />
                <li />
              </ul>
            </div>
            <div className="heading_content">
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-xs-12 content_sec">
            <div className="blog-slider owl-five owl-carousel owl-theme">
              <div className="item blog_card">
                <div className="post-img">
                  {" "}
                  <img src="img/blog-post-1.jpg" alt="Post" />
                  <div className="posted_on">
                    {" "}
                    <span className="date">04</span>{" "}
                    <span className="month">Sept.</span>{" "}
                  </div>
                </div>
                <div className="post-detail">
                  <h5>
                    <a href="blog-detail.html">
                      Standard is simply dummy text of the printing.
                    </a>
                  </h5>
                  <div className="post-status">
                    <ul>
                      <li>
                        <i className="flat flaticon-folder" />{" "}
                        <span>
                          <a href="javascript:void(0)">UI Developer</a>
                        </span>
                      </li>
                      <li>
                        <i className="flat flaticon-chat" /> <span>20</span>
                      </li>
                    </ul>
                  </div>
                  <div className="description">
                    <p>
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </p>
                    <a
                      href="blog-detail.html"
                      className="view_more"
                      title="View More"
                    >
                      View More
                    </a>{" "}
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="item blog_card">
                <div className="post-img">
                  {" "}
                  <img src="img/blog-post-2.jpg" alt="Post" />
                  <div className="posted_on">
                    {" "}
                    <span className="date">05</span>{" "}
                    <span className="month">Sept.</span>{" "}
                  </div>
                </div>
                <div className="post-detail">
                  <h5>
                    <a href="blog-detail.html">
                      If you are going to use a passage of Lorem Ipsum.
                    </a>
                  </h5>
                  <div className="post-status">
                    <ul>
                      <li>
                        <i className="flat flaticon-folder" />{" "}
                        <span>
                          <a href="javascript:void(0)">UI Developer</a>
                        </span>
                      </li>
                      <li>
                        <i className="flat flaticon-chat" /> <span>20</span>
                      </li>
                    </ul>
                  </div>
                  <div className="description">
                    <p>
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </p>
                    <a
                      href="blog-detail.html"
                      className="view_more"
                      title="View More"
                    >
                      View More
                    </a>{" "}
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="item blog_card">
                <div className="post-img">
                  {" "}
                  <img src="img/blog-post-3.jpg" alt="Post" />
                  <div className="posted_on">
                    {" "}
                    <span className="date">06</span>{" "}
                    <span className="month">Sept.</span>{" "}
                  </div>
                </div>
                <div className="post-detail">
                  <h5>
                    <a href="blog-detail.html">
                      There are many variations of passages of Lorem Ipsum
                      available
                    </a>
                  </h5>
                  <div className="post-status">
                    <ul>
                      <li>
                        <i className="flat flaticon-folder" />{" "}
                        <span>
                          <a href="javascript:void(0)">UI Developer</a>
                        </span>
                      </li>
                      <li>
                        <i className="flat flaticon-chat" /> <span>20</span>
                      </li>
                    </ul>
                  </div>
                  <div className="description">
                    <p>
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </p>
                    <a
                      href="blog-detail.html"
                      className="view_more"
                      title="View More"
                    >
                      View More
                    </a>{" "}
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="item blog_card">
                <div className="post-img">
                  {" "}
                  <img src="img/blog-post-1.jpg" alt="Post" />
                  <div className="posted_on">
                    {" "}
                    <span className="date">07</span>{" "}
                    <span className="month">Sept.</span>{" "}
                  </div>
                </div>
                <div className="post-detail">
                  <h5>
                    <a href="blog-detail.html">
                      Standard is simply dummy text of the printing.
                    </a>
                  </h5>
                  <div className="post-status">
                    <ul>
                      <li>
                        <i className="flat flaticon-folder" />{" "}
                        <span>
                          <a href="javascript:void(0)">UI Developer</a>
                        </span>
                      </li>
                      <li>
                        <i className="flat flaticon-chat" /> <span>20</span>
                      </li>
                    </ul>
                  </div>
                  <div className="description">
                    <p>
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </p>
                    <a
                      href="blog-detail.html"
                      className="view_more"
                      title="View More"
                    >
                      View More
                    </a>{" "}
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="item blog_card">
                <div className="post-img">
                  {" "}
                  <img src="img/blog-post-2.jpg" alt="Post" />
                  <div className="posted_on">
                    {" "}
                    <span className="date">08</span>{" "}
                    <span className="month">Sept.</span>{" "}
                  </div>
                </div>
                <div className="post-detail">
                  <h5>
                    <a href="blog-detail.html">
                      If you are going to use a passage of Lorem Ipsum.
                    </a>
                  </h5>
                  <div className="post-status">
                    <ul>
                      <li>
                        <i className="flat flaticon-folder" />{" "}
                        <span>
                          <a href="javascript:void(0)">UI Developer</a>
                        </span>
                      </li>
                      <li>
                        <i className="flat flaticon-chat" /> <span>20</span>
                      </li>
                    </ul>
                  </div>
                  <div className="description">
                    <p>
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </p>
                    <a
                      href="blog-detail.html"
                      className="view_more"
                      title="View More"
                    >
                      View More
                    </a>{" "}
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="item blog_card">
                <div className="post-img">
                  {" "}
                  <img src="img/blog-post-3.jpg" alt="Post" />
                  <div className="posted_on">
                    {" "}
                    <span className="date">09</span>{" "}
                    <span className="month">Sept.</span>{" "}
                  </div>
                </div>
                <div className="post-detail">
                  <h5>
                    <a href="blog-detail.html">
                      There are many variations of passages of Lorem Ipsum
                    </a>
                  </h5>
                  <div className="post-status">
                    <ul>
                      <li>
                        <i className="flat flaticon-folder" />{" "}
                        <span>
                          <a href="javascript:void(0)">UI Developer</a>
                        </span>
                      </li>
                      <li>
                        <i className="flat flaticon-chat" /> <span>20</span>
                      </li>
                    </ul>
                  </div>
                  <div className="description">
                    <p>
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </p>
                    <a
                      href="blog-detail.html"
                      className="view_more"
                      title="View More"
                    >
                      View More
                    </a>{" "}
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </section>
    );
  }
}

export default blog;
