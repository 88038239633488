import React, { Component } from 'react';
import "./t.css"
class test extends Component {
    
    render() { 
        return (  
            <div className="tcontainer">
            <div className="ticker-wrap">
              <div className="ticker-move">
                <div className="ticker-item">Functional Consultants Required          </div>
                <div className="ticker-item">Oracal Developers Required               </div>
                <div className="ticker-item">Post your CVs on Career Page             </div>
              </div>
            </div>
          </div>
        );
    }
}
 
export default test;