import React, { Component } from 'react';

import './port-card.css'
class Portfolio3 extends Component {
   
    render() { 
        return (
        <React.Fragment>
           

 <section id="contact-us">
<div className="container">

                    
                    <div className="form-header text-center">
                      <h4>ERP</h4>
                      <div className="separator">
                        <ul>
                          <li />
                          <li />
                          <li />
                        </ul> 
                      </div>
                      <p>Type: ERP, e-Commerce, Mobile Application, Website</p>
                    </div>
<div className="contact-form ">


                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="container">
            
            <figure className="c-img">
              <img className="ie" src="img/port_pics/videoblocks-financial-system-animated-word-cloud-text-design-animation_b1fxc6oal_thumbnail-full08.png" />
              <figcaption><h4>WIZMEN FINANCIALS SYSTEM</h4><br/>
                <p className="fig">-Wizmen General Ledger System<br/>-Wizmen Cash Management System<br/>-Wizmen Account Receivable System<br/>-Wizmen Sales Management System<br/>-Wizmen Payable System<br/>-Wizmen Purchase Management System<br/>-Wizmen Fixed Assets Management System<br/>-Wizmen Budgeting System</p></figcaption>
            </figure>
            <figure className="c-img">
              <img className="ie" src="img/port_pics/supply_chain.png" />
              <figcaption><h4>WIZMEN SUPPLY CHAIN MANAGEMENT SYSTEM</h4><br/>
                <p className="fig">-Wizmen Inventory Management System<br/>-Wizmen Procurement System<br/>-Wizmen Import Management System</p></figcaption>
            </figure>
            <figure className="c-img">
              <img  className="ie" src="img/port_pics/hrms-banner.png" />
              <figcaption><h4>WIZMEN HUMAN CAPITAL MANGEMENT</h4><br/>
                <p className="fig">-Wizmen HR Management System<br/>-Wizmen Attendance System<br/>-Wizmen Payroll System<br/>-Wizmen PFT Management System</p></figcaption>
            </figure>
            <figure className="c-img">
              <img src="img/port_pics/futura-retail-management-icon-en.png" className="ie" />
              <figcaption><h4>WIZMEN RETAIL MANAGEMENT SYSTEM</h4><br/>
                <p className="fig">-Wizmen Point Of Sales System<br/>-Wizmen Warehouse Management System</p></figcaption>
            </figure>
            <figure className="c-img">
              <img src="img/port_pics/Qualified+Icon.png" className="ie"/>
              <figcaption><h4>WIZMEN MANUFACTURING SYSTEM</h4><br/>
                <p className="fig">-Wizmen Bill of Material<br/>-Wizmen Job Order System<br/>-Wizmen Production Scheduling System</p></figcaption>
            </figure>
          </div>
                              
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            
                            
                            <div className="container">
            
            <figure className="c-img">
              <img src="img/port_pics/ProcurementSystem_Blog.png" className="ie"/>
              <figcaption><h4>WIZMEN TEXTILES</h4><br/>
                <p className="fig">-Wizmen Yarn Sales System<br/>-Wizmen Yarn Procurement System<br/>-Wizmen Yarn Production System<br/>-Wizmen Cotton Procurement System</p></figcaption>
            </figure>
            <figure className="c-img">
              <img src="img/port_pics/project-management.png" className="ie"/>
              <figcaption><h4>WIZMEN CONSTRUCTION SYSTEM</h4><br/>
                <p className="fig">-Wizmen Contracting System<br/>-Wizmen Building Control System<br/>-Wizmen Project Costing System</p></figcaption>
            </figure>
            <figure className="c-img">
              <img src="img/port_pics/rems.png" className="ie" />
              <figcaption><h4>WIZMEN REAL ESTATE SYSTEM</h4><br/>
                <p className="fig">-Wizmen Real Estate CRM System<br/>-Wizmen Balloting System<br/>-Wizmen Building Control System<br/>-Wizmen Utility Billing System<br/>-Wizmen Land Management System<br/>-Wizmen Customer Alert System</p></figcaption>
            </figure>
            <figure className="c-img">
              <img src="img/port_pics/fb5781e999fbc2a1d6952265d54e971e.png" className="ie"/>
              <figcaption><h4>WIZMEN RESTAURANT MANAGEMENT SYSTEM</h4><br/>
                <p className="fig">-Wizmen Expeditor Board<br/>-Wizmen Kitchen Board<br/>-Wizmen Point Of Sales<br/>-Wizmen Recipe System</p></figcaption>
            </figure>
            <figure className="c-img">
              <img className="ie" src="img/port_pics/fee2.png" />
              <figcaption><h4>OTHER</h4><br/>
                <p className="fig">-Wizmen Audit Log Management System<br/>-Wizmen Fee Management System</p></figcaption>
            </figure>
          </div>
                             
                            </div>
                            
                           
                          </div>
                       




</div></div></section>


<section id="contact-us">
<div className="container">

                    
                    <div className="form-header text-center">
                      <h4>Mobile Apps</h4>
                      <div className="separator">
                        <ul>
                          <li />
                          <li />
                          <li />
                        </ul> 
                      </div>
                      <p>Type: ERP, e-Commerce, Mobile Application</p> 
                    </div>
<div className="contact-form ">


                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="container">
            
            <figure className="c-img">
              <img src="img/port_pics/order-booking-system-500x500.png" className="ie"/>
              <figcaption><h4>ORDER BOOKING</h4><br/>
                <p className="fig"></p>
                </figcaption>
            </figure>
            <figure className="c-img">
              <img src="img/port_pics/collections-of-accounts-receivable-software-1024x859.png" className="ie"/>
              <figcaption><h4>PAYMENT COLLECTION</h4><br/>
                <p className="fig"></p></figcaption>
            </figure>
            <figure className="c-img">
              <img src="img/port_pics/POS-Software-for-Management-Karachi-Web-Application.png" className="ie"/>
              <figcaption><h4>POINT OF SALES</h4><br/>
                <p className="fig"></p></figcaption>
            </figure>
            <figure className="c-img">
              <img src="img/port_pics/banner-admin-core.png"  className="ie"/>
              <figcaption><h4>ORDER PULLING FROM WAREHOUSE</h4><br/>
                <p className="fig">""</p></figcaption>
            </figure>
          </div>
                              
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            
                            
                            <div className="container">
            
            <figure className="c-img">
              <img src="img/port_pics/business-3152586__340.jpg" className="ie" />
              <figcaption><h4>BUSINESS PICTURES SHOOTING</h4><br/>
                <p className="fig">""</p></figcaption>
            </figure>
            <figure className="c-img">
              <img src="img/port_pics/0_uG2eriJCcfm1Bgbb_.png" className="ie" />
              <figcaption><h4>SELF SERVICE PAYROLL</h4><br/>
                <p className="fig">""</p></figcaption>
            </figure>
            <figure className="c-img">
              <img src="img/port_pics/Solution-Banner.png" className="ie"/>
              <figcaption><h4>ERP DOCUMENT AUTHORIZATION</h4><br/>
                <p className="fig">""</p></figcaption>
            </figure>
            <figure className="c-img">
              <img src="img/port_pics/push.png" className="ie"/>
              <figcaption><h4>BUSINESS NOTIFICATIONS</h4><br/>
                <p className="fig">""</p></figcaption>
            </figure>
          </div>
                             
                            </div>
                            
                           
                          </div>
                       




</div></div></section>






<section id="contact-us">
<div className="container">

                    
                    <div className="form-header text-center">
                      <h4>Web Apps</h4>
                      <div className="separator">
                        <ul>
                          <li />
                          <li />
                          <li />
                        </ul> 
                      </div>
                     <p>Type: ERP, e-Commerce, Mobile Application, Website</p> 
                    </div>
<div className="contact-form ">


                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="container">
            
            <figure className="c-img">
              <img src="img/port_pics/6703b363845487.5abe2a1404c51.jpg" className="ie" />
              <figcaption><h4>SNAP TO SELL</h4><br/>
                <p className="fig"></p></figcaption>
            </figure>
            <figure className="c-img">
              <img src="img/portfolio/thumbnail/project_5.jpg" className="ie"/>
              <figcaption><h4>LAND MANAGEMENT SYSTEM</h4><br/>
                <p className="fig"></p></figcaption>
            </figure>
            <figure className="c-img">
              <img src="img/port_pics/POS-Software-for-Management-Karachi-Web-Application.png" className="ie"/>
              <figcaption><h4>POINT OF SALE</h4><br/>
                <p className="fig"></p></figcaption>
            </figure>
           
          </div>
                              
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            
                            
                            <div className="container">
            
            <figure className="c-img">
              <img src="img/portfolio/thumbnail/project_1.jpg" className="ie" />
              <figcaption><h4>IMPERIAL TRADE AGENCY PHP SERVICE</h4><h5>FOR LIVE TO ORACAL LAPTOP ZONE</h5>
                <p className="fig"></p></figcaption>
            </figure>
            <figure className="c-img">
              <img src="img/port_pics/images (1).jpg" className="ie"/>
              <figcaption><h4>ECOMMERCE SITE</h4><br/>
                <p className="fig"></p></figcaption>
            </figure>
          </div>
                             
                            </div>
                            
                           
                          </div>
                       




</div></div></section>



<section id="contact-us">
<div className="container">

                    
                    <div className="form-header text-center">
                      <h4>Cross-Platform Apps</h4>
                      <div className="separator">
                        <ul>
                          <li />
                          <li />
                          <li />
                        </ul> 
                      </div>
                      <p>Type: ERP, e-Commerce, Mobile Application, Website</p> 
                    </div>
<div className="contact-form ">


                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="container">
            
            <figure className="c-img">
              <img src="img/port_pics/1_6xT0ZOACZCdy_61tTJ3r1Q.png" className="ie" />
              <figcaption><h4>FLUTTER</h4><br/>
                <p className="fig">"Google’s Flutter is a free and open source mobile app SDK which is used to develop top-quality native interfaces for Android and iOS in record time. The new framework uses a single codebase for both Android and IOS platform, thus plummeting the turnaround time.

This new launch by Google is not just anticipated to ease and fasten the development process but is also expected to extend an improved app experience to users.

At Wizmen Systems, we have a skilled team of developers, who can offer flutter app development services faultlessly."</p></figcaption>
            </figure>
            
          </div>
                              
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            
                            
                            <div className="container">
            
            <figure className="c-img">
              <img src="img/port_pics/download (1).png" className="ie"/>
              <figcaption><h4>REACT NATIVE</h4><br/>
                <p className="fig">"React Native is a JavaScript framework which comprises advanced features to deliver groundbreaking mobile app development solutions for both iOS and Android. It allows you to build native applications in JavaScript by reusing the code across mobile and web, while still delivering a real native user interface.

Being leading react native app development company, we build powerful multi-platform apps based on React Native platform. These apps are backed by a set of aesthetically pleasing User Interface, delivering an interactive user experience."</p></figcaption>
            </figure>
           
          </div>
                             
                            </div>
                            
                           
                          </div>
                       




</div></div></section>
</React.Fragment>




          );
    }
}
 
export default Portfolio3;