


import React from "react";
   
import axios from "axios";
import {Progress} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//const cors = require('cors');

function myFunction() {
  document.getElementById("myForm").reset();
 
}
function validateForm1() {
  var a = document.forms["myForm"]["full_name"].value;
  var b = document.forms["myForm"]["address"].value;
  var c = document.forms["myForm"]["email"].value;
  var d = document.forms["myForm"]["phone"].value;
  var e = document.forms["myForm"]["position"].value;
  var f = document.forms["myForm"]["emp_status"].value;
  var g = document.forms["myForm"]["education"].value;
  var h = document.forms["myForm"]["message"].value;
  var i = document.forms["myForm"]["updated_cv"].value;
  if (a == "") {
    alert("FullName must be filled out");
    return false;
  }
  
   if (b == "") {
    alert("Address must be filled out");
    return false;
  }
  if (c == "") {
    alert("Email must be filled out");
    return false;
  }
  if (d == "") {
    alert("Phone must be filled out");
    return false;
  }
  if (e == "") {
    alert("Position must be filled out");
    return false;
  }
  if (f == "") {
    alert("Employee Status must be filled out");
    return false;
  }
  if (g == "") {
    alert("Education must be filled out");
    return false;
  }
  if (h == "") {
    alert("Message must be filled out");
    return false;
  }
  if (i == "") {
    alert("Updated CV must be attach");
    return false;
  }else{
    return true;
  }


}
 

class career extends React.Component {
  
  constructor() {
    super();
    this.state = {
     site_url:'http://localhost/wiztest/index.php',
     error: {},
     btnloading: false,
     full_name:'',
     address: '',
     email:'',
     phone:'',
     position: '',
     emp_status:'',
     education:'',
     message: '',
     updated_cv:'',
     selectedFile: '',
     loaded:0

    }
    this.onChange = this.onChange.bind(this);
  }
  componentWillMount(){
      this.setState({
          //site_url: window['site_url']
      });
  }

  checkMimeType=(event)=>{
    //getting file object
    let files = event.target.files 
    //define message container
    let err = []
    // list allow mime type
   const types = ['application/pdf','application/vnd.openxmlformats-officedocument.wordprocessingml.document','	application/msword']
    // loop access array
    for(var x = 0; x<files.length; x++) {
     // compare file type find doesn't matach
         if (types.every(type => files[x].type !== type)) {
         // create error message and assign to container   
         err[x] = files[x].type+' is not a supported format\n';
       }
     };
     for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
         // discard selected file
        toast.error(err[z])
        event.target.value = null
    }
   return true;
  }
  maxSelectFile=(event)=>{
    let files = event.target.files
        if (files.length > 3) { 
           const msg = 'Only 3 images can be uploaded at a time'
           event.target.value = null
           toast.warn(msg)
           return false;
      }
    return true;
 }
 checkFileSize=(event)=>{
  let files = event.target.files
  let size = 5000000 
  let err = []; 
  for(var x = 0; x<files.length; x++) {
  if (files[x].size > size) {
   err[x] = files[x].type+'is too large, please pick a smaller file\n';
 }
};
for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
  // discard selected file
 toast.error(err[z])
 event.target.value = null
}
return true;
}

// onChangeHandler=event=>{
//   var files = event.target.files
//   if(this.maxSelectFile(event) && this.checkMimeType(event) &&    this.checkFileSize(event)){ 
//   // if return true allow to setState
//      this.setState({
//      selectedFile: files,
//      loaded:0
//   })
// }
// }
  // onClickHandler = () => {
  //   const data = new FormData() 
   
  //   for(var x = 0; x<this.state.selectedFile.length; x++) {
  //     data.append('file', this.state.selectedFile[x])
  //   }
   
  //   axios.post(this.state.site_url+ '/welcome/cv', data, {
  //     onUploadProgress: ProgressEvent => {
  //       this.setState({
  //         loaded: (ProgressEvent.loaded / ProgressEvent.total*100),
  //       })
  //     },
  //   })
  //             .then(res => { // then print response status
  //       toast.success('upload success')
  //     })
  //     .catch(err => { // then print response status
  //       toast.error('upload fail')
  //     })
    
      
      
  //   }


onChange(e){
this.setState({[e.target.name]: e.target.value});

}


onChange2=event=>{
  var files = event.target.files
  //if(this.maxSelectFile(event) && this.checkMimeType(event) &&    this.checkFileSize(event)){ 
    if(this.maxSelectFile(event)  &&    this.checkFileSize(event)){
  // if return true allow to setState
     this.setState({
     selectedFile: files,
     loaded:0
  })
}
}

submitBiodata(e)
{
  if(validateForm1()){
e.preventDefault();
this.setState({btnloading:true});

const formData = new FormData();
formData.append('full_name',this.state.full_name);
formData.append('address',this.state.address);
formData.append('email',this.state.email);
formData.append('phone',this.state.phone);
formData.append('position',this.state.position);
formData.append('emp_status',this.state.emp_status);
formData.append('education',this.state.education);
formData.append('message',this.state.message);
//formData.append('updated_cv',this.state.updated_cv[0]);
formData.append('updated_cv',this.state.selectedFile[0]);

axios.post('http://localhost/wiztest/composer/career.php',formData,{
 headers: {
    'Content-Type': 'multipart/form-data'
  }
}).then(res => {});



axios.post(this.state.site_url+ '/welcome/apply',formData).then(res => {
    const response =res.data;

    if(response.status){
        this.setState({error:{}});
        alert(response.message);

        this.setState({
          full_name:'',
          address: '',
          email:'',
          phone:'',
          position: '',
          emp_status:'',
          education:'',
          message: '',
          updated_cv:''

        });
    }else{
       
        this.setState({error:response.error});
        alert(response.message);
    }

}).then(() =>{
    this.setState({btnloading:false});
}).catch(error =>{
    alert(error.response.status +':'+ error.response.statusText);
  
});
myFunction();

}

}
  render() { //update this with your $form selector
    
    return (

        <section id="contact-us">
        <div className="container">

<div className="">
                    
                      <div className="form-header text-center">
                        <h4>Job Application Form</h4>
                        <div className="separator">
                          <ul>
                            <li />
                            <li />
                            <li />
                          </ul> 
                        </div>
                        <p>It would be great to hear from you! If you got any questions</p>
                      </div>
                      <div className="contact-form ex">
                        <form name="myForm" action="" method="get/post" encType="multipart/form-data" id="myForm" onsubmit="return validateForm1()">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <div className="form-group">
                              
                                <input defaultValue type="text" placeholder="Full Name" name="full_name" value={this.setState.first_name} onChange={this.onChange}  defaultValue={""} required/>
                              </div>
                              <div className="form-group">
                                <input defaultValue type="text" placeholder="Address" name="address" value={this.setState.address} onChange={this.onChange}   defaultValue={""}/>
                              </div>



                              <div className="form-group">
                                <input defaultValue type="text" value={this.setState.email} onChange={this.onChange} name="email"  placeholder="Email" defaultValue={""} required/>
                              </div>

                              

                              <div className="form-group">
                                <input defaultValue type="text" value={this.setState.phone} onChange={this.onChange} placeholder="Phone No." name="phone" defaultValue={""}/>
                              </div>

                              <div className="form-group">
                              <h6>Applying For Position</h6>     
  <select className="form-control" id="sel1" name="position" type="text" value={this.setState.position} onChange={this.onChange} required>
  
  <option value="" selected disabled>Please Select</option>
    <option>Oracle</option>
    <option>Web App</option>
    <option>Android</option>
  </select>
</div>

                              <div className="form-group">
                              <h6>What is your current employment status?</h6>
                              <select className="form-control" id="sel1" name="emp_status" type="text" value={this.setState.emp_status} onChange={this.onChange} required>
                              <option value="" selected disabled>Please Select</option>
 
    <option>Employed</option>
    <option>Unemployed</option>
    <option>Self-Employed</option>
    <option>Student</option>
    <option>Intern</option>
  </select>
                              </div>
                              
         


                            
                            
   
                              <div className="form-group">
                              <h6>Education:</h6>
                              <select className="form-control" id="sel1" name="education" type="text" value={this.setState.education} onChange={this.onChange} required>
                              <option value="" selected disabled>Please Select</option>
 
    <option>Bs Computer Science</option>
    <option>ACCA</option>
    <option>MBA</option>
    <option>BBA</option>
    <option>B.COM</option>
    <option>Ms Computer Science</option>
  </select>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                              <textarea placeholder="Message" type="text" value={this.setState.message} onChange={this.onChange} name="message"defaultValue={""} />
                               <div className="form-group">
<h6>Attach Updated Cv:</h6>

<input type="file" name="updated_cv" accept=".docx,.doc,.pdf"   onChange={this.onChange2} required />
</div> 
               {/* <div className="form-group files">
               <h6>Attach Updated Cv:</h6>
                <input type="file"  className="form-control"   multiple onChange={this.onChangeHandler}  required />
              </div>   */}
              {/* <div className="form-group">
              <ToastContainer />
              <Progress max="100" color="success" value={this.state.loaded} >{Math.round(this.state.loaded,2) }%</Progress>
        
              </div> 
               */}
              {/* <button type="button" className="btn btn-success btn-block" onClick={this.onClickHandler}>Upload</button> */}

                            </div>
                            
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group text-center mar-none">
                              <button disabled={this.state.btnloading}
                onClick={this.submitBiodata.bind(this)} 
                type="submit">{this.state.btnloading ? 'loading...':'Apply'}<i className="flat flaticon-plane" /></button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>    </div>
          </section>
    );
  }
}

export default career;
