import React from "react";
import axios from 'axios';
class ContactForm extends React.Component {

  constructor() {
    super();
    this.state = {
     site_url:'http://localhost/wiztest/index.php',
     error: {},
     btnloading: false,
     full_name:'',
     email: '',
     subject:'',
     message:''
    }
    this.onChange = this.onChange.bind(this);
  }
  componentWillMount(){
      this.setState({
          //site_url: window['site_url']
      });
  }
  componentDidMount(){

  }


onChange(e){
this.setState({[e.target.name]: e.target.value});
}




submitBiodata(e)
{
e.preventDefault();
this.setState({btnloading:true});
const formData = new FormData();
formData.append('full_name',this.state.full_name);
formData.append('email',this.state.email);
formData.append('subject',this.state.subject);
formData.append('message',this.state.message);

axios.post('http://localhost/wiztest/composer/home_contactform.php',formData).then(res => {});


axios.post(this.state.site_url+ '/welcome/contactform',formData).then(res => {
    const response =res.data;

    if(response.status){
        this.setState({error:{}});
        alert(response.message);

        this.setState({
          full_name:'',
          email: '',
          subject:'',
          message:''
        });
    }else{
       
        this.setState({error:response.error});
        alert(response.message);
    }

}).then(() =>{
    this.setState({btnloading:false});
}).catch(error =>{
    alert(error.response.status +':'+ error.response.statusText);
  
});
}


  render() {
    return (
      <div>
         <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br> <br></br>
           <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
      <footer className="home index-2">
        <div className="container">
          <div className="col-lg-12">
            <div className="row row-safari">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="section_heading">
                  <h2>Contact Info</h2>
                  <div className="separator left-align">
                    <ul>
                      <li />
                      <li />
                      <li />
                    </ul>
                  </div>
                </div>
                <div className="contact_info">
                  <ul>
                    <li>
                      <div className="icon">
                        <i className="flat flaticon-facebook-placeholder-for-locate-places-on-maps" />
                      </div>
                      <div className="detail">
                        <h5>Address</h5>
                        <div className="content">
                        Head Office :<br/> <span class="value"> Pakistan Office: 646 Block 1-C-1, Township, Lahore – Pakistan, Country Name, Pin Code : 54000 </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="flat flaticon-phone-receiver" />
                      </div>
                      <div className="detail">
                        <h5>Call Us On</h5>
                        <div className="content">
                        Main Office : <span class="value">  +92-334-9706505  </span> <br /> 
                      Customer Support : <span class="value">  +92-300-4198433    </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="flat flaticon-mail-black-envelope-symbol" />
                      </div>
                      <div className="detail">
                        <h5>Mail Us On</h5>
                        <div className="content">
                        Customer Support : <span class="value"><a href="javascript:void(0)" title="support@bizler.com"> info@wizmen.net</a></span><br />
                      Technical Support : <span class="value"><a href="javascript:void(0)" title="support@bizler.com"> nadeem@wizmen.net</a></span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="flat flaticon-share" />
                      </div>
                      <div className="detail">
                        <h5>Follow Us On</h5>
                        <div className="content">
                          <a href="javascript:void(0)" title="Facebook">
                            <i className="flat flaticon-facebook-logo" />
                          </a>
                          <a href="javascript:void(0)" title="Twitter">
                            <i className="flat flaticon-twitter" />
                          </a>
                          <a href="javascript:void(0)" title="Google">
                            <i className="flat flaticon-google-plus-logo" />
                          </a>
                          <a href="javascript:void(0)" title="Linkedin">
                            <i className="flat flaticon-linkedin-logo" />
                          </a>
                          <a href="javascript:void(0)" title="Instagram">
                            <i className="flat flaticon-instagram" />
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="section_heading">
                  <h2>Contact Form</h2>
                  <div className="separator left-align">
                    <ul>
                      <li />
                      <li />
                      <li />
                    </ul>
                  </div>
                </div>
                <div className="contact-form">
                  <form action=""  method="POST">
                  <div className="form-group">
                                <input defaultValue type="text" placeholder="Full Name" value={this.setState.full_name} onChange={this.onChange} name="full_name" defaultValue={""}/>
                              </div>
                              <div className="form-group">
                                <input defaultValue type="text" placeholder="Email" value={this.setState.email} onChange={this.onChange} name="email" defaultValue={""} />
                              </div>
                              
                              <div className="form-group">
                                <input defaultValue type="text" placeholder="Subject" value={this.setState.subject} onChange={this.onChange} name="subject" defaultValue={""} />
                              </div>
                            
                            <div className="form-group">
                              <textarea placeholder="Message" value={this.setState.message} onChange={this.onChange} name="message" defaultValue={""} />
                            </div>
                    <div className="form-group">
                      <button disabled={this.state.btnloading}
                onClick={this.submitBiodata.bind(this)}
                type="submit">{this.state.btnloading ? 'loading...':'submit'}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        </footer>
      </div>
    );
  }
}

export default ContactForm;
